import {Helmet} from 'react-helmet';

const Promo = () => {

  return (
    <>
      <Helmet>
        <title>Welcome to chernykh.tech</title>
      </Helmet>
      <section className="promo">
        <div className="promo__container">
          <div className="promo__rings-wrapper">
            <svg width="60" height="318" viewBox="0 0 60 318" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M30 0C13.4315 0 0 13.4315 0 30V100C0 116.569 13.4315 130 30 130C46.5685 130 60 116.569 60 100V30C60 13.4315 46.5685 0 30 0ZM30 10C18.9543 10 10 18.9543 10 30V100C10 111.046 18.9543 120 30 120C41.0457 120 50 111.046 50 100V30C50 18.9543 41.0457 10 30 10Z" fill="#5A9DFD"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M30 134C13.4315 134 0 147.431 0 164V224C0 240.569 13.4315 254 30 254C46.5685 254 60 240.569 60 224V164C60 147.431 46.5685 134 30 134ZM30 144C18.9543 144 10 152.954 10 164V224C10 235.046 18.9543 244 30 244C41.0457 244 50 235.046 50 224V164C50 152.954 41.0457 144 30 144Z" fill="#F2BB05"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M30 258C13.4315 258 0 271.431 0 288C0 304.569 13.4315 318 30 318C46.5685 318 60 304.569 60 288C60 271.431 46.5685 258 30 258ZM30 268C18.9543 268 10 276.954 10 288C10 299.046 18.9543 308 30 308C41.0457 308 50 299.046 50 288C50 276.954 41.0457 268 30 268Z" fill="#E84855"/>
            </svg>
          </div>
          <div className="promo__text-wrapper">
          <span className="promo__welcome-text">
            Welc<span className="red">o</span>me t<span className="blue">o</span> <br/>  chernykh.<span className="blue">tech</span>
          </span>
            <p className="promo__description">
              We are a team<br className="mobile"/> <span className="blue">o</span>f beautiful<br className="mobile"/> and handy<br className="mobile"/> devel<span className="yellow">o</span>pers wh<span className="red">o</span><br className="mobile"/> create c<span className="yellow">o</span><span className="blue">o</span>l and<br className="mobile"/> interesting things!
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Promo;