import {Link} from "react-router-dom";
import {questions} from "../data/questions";
import {Helmet} from "react-helmet";

const FaqPage = () => {

  return (
    <>
      <Helmet>
        <title>Periodic Table: FAQ — chernykh.tech</title>
      </Helmet>
      <section className="faq">
        <div className="app-page__container faq__container">
          <h2 className="app-page__title faq__title">Periodic Table: <span className='red'>FAQ</span></h2>
          <h3 className="faq__subtitle">Frequently asked questions</h3>
          <p className="faq__page-description">
            On the <span className="red">FAQ</span> page you will find useful information about our application and
            get <span className="light-green">answers</span> to your <span className="yellow">questions</span>.
            If you don't find the answer on this page, feel free to contact us through our support <span
            className="blue">team</span>.
            We are always ready to help you!
          </p>
          <b className="faq__list-title">Frequent questions from users:</b>
          <ul className="faq__list">
            {questions.map((question, index) => (
              <li className="faq__item" key={index}>
                <Link to={`/faq/${question.id}`} className='faq__item-link'>
                  <div className="faq__image-wrapper">
                    <img
                      className='faq__item-icon'
                      src={require(`../assets/images/faq/icons/${question.previewIcon}`)}
                      alt=""
                    />
                  </div>
                  <div className='faq__item-description-wrapper'>
                    <h3 className="faq__item-title">{question.title}</h3>
                    <p className="faq__item-description">{question.shortDescription}</p>
                  </div>
                </Link>
              </li>
            ))}
            {/*<li className="faq__item">*/}
            {/*  <Link to='/faq/1' className='faq__item-link'>*/}
            {/*    <img className='faq__item-icon' src={require('../assets/images/faq/icons/fullscreen.svg').default} alt=""/>*/}
            {/*    <div className='faq__item-description-wrapper'>*/}
            {/*      <h3 className="faq__item-title">What are the advantages of the PRO version?</h3>*/}
            {/*      <p className="faq__item-description">Read more</p>*/}
            {/*    </div>*/}
            {/*  </Link>*/}
            {/*</li>*/}
            {/*<li className="faq__item">*/}
            {/*  <Link to='/faq/1' className='faq__item-link'>*/}
            {/*    <h3 className="faq__item-title">How to find a chemical element by symbols?</h3>*/}
            {/*    <p className="faq__item-description">Search > Filter > Element symbol</p>*/}
            {/*  </Link>*/}
            {/*</li>*/}
            {/*<li className="faq__item">*/}
            {/*  <Link to='/faq/1' className='faq__item-link'>*/}
            {/*    <h3 className="faq__item-title">How to enable fullscreen mode?</h3>*/}
            {/*    <p className="faq__item-description">Other > Setting > Form table</p>*/}
            {/*  </Link>*/}
            {/*</li>*/}
          </ul>
        </div>
      </section>
    </>
  )
}

export default FaqPage;