import {useEffect, useState} from 'react';
import {useWindowSize} from './services.js';
import {NavLink, Link} from 'react-router-dom';
import SocialLink from "./SocialLink";

const Menu = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [width] = useWindowSize();

  useEffect(() => {
    if (width >= 1280) {
      setShowMenu(true);
    } else if (width < 1280) {
      setShowMenu(false);
      document.body.style.overflow = 'visible';
    }
  }, [width]);

  useEffect(() => {
    if (showMenu === true && width < 1280) {
      document.body.style.overflow = 'hidden';
      window.scrollTo(0, 0);
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [showMenu, width]);

  return (
    <section className="sidebar-menu">
      <section className="page-header">
        <div className="page-header__container">
          <Link to="/" onClick={() => window.scrollTo(0,0)}>
            <div className="page-header__logo" onClick={() => {
              if (width < 1280) {
                setShowMenu(false);
                document.body.style.overflow = 'visible';
              }
            }}>
              <img src={require('../assets/images/logo-light.svg').default} alt="" />
            </div>
          </Link>
          {width < 1280 ?
            <button className={`page-header__menu-btn ${showMenu ?
              'page-header__menu-btn--opened' :
              ''}`} onClick={() => setShowMenu(!showMenu)} aria-label="Menu" /> : null}
        </div>
      </section>
      {showMenu ? <section className={`menu ${showMenu ? 'menu--opened' : ''}`}>
        <div className="menu__container">
          <h2 className="menu__category">Periodic table:</h2>
          <ul className="menu__list">
            <li className="menu__item menu__item--web" onClick={() => {
              if (width < 1280) {
                setShowMenu(false);
                document.body.style.overflow = 'visible';
              }
            }}>
              <div>
                <NavLink to="/pt-web" className="menu__link menu__link--web" activeClassName="menu__link--web--current" onClick={() => window.scrollTo(0,0)}>
                  <h3 className="menu__project-name">WEB Version</h3>
                  <p className="menu__project-descr">Chemistry for the big screen</p>
                </NavLink>
              </div>
            </li>
            <li className="menu__item menu__item--mobile" onClick={() => {
              if (width < 1280) {
                setShowMenu(false);
                document.body.style.overflow = 'visible';
              }
            }}>
              <NavLink to="/pt-mobile" className="menu__link menu__link--mobile" activeClassName="menu__link--mobile--current" onClick={() => window.scrollTo(0,0)}>
                <h3 className="menu__project-name">Mobile version</h3>
                <p className="menu__project-descr">Chemistry in your pocket</p>
              </NavLink>
            </li>
            <li className="menu__item menu__item--game" onClick={() => {
              if (width < 1280) {
                setShowMenu(false);
                document.body.style.overflow = 'visible';
              }
            }}>
              <NavLink to="/pt-game" className="menu__link menu__link--game" activeClassName="menu__link--game--current" onClick={() => window.scrollTo(0,0)}>
                <h3 className="menu__project-name">Android game</h3>
                <p className="menu__project-descr">Play — learn</p>
              </NavLink>
            </li>
          </ul>
          <h2 className="menu__category">Other projects:</h2>
          <ul className="menu__list">
            <li className="menu__item menu__item--universe-say" onClick={() => {
              if (width < 1280) {
                setShowMenu(false);
                document.body.style.overflow = 'visible';
              }
            }}>
              <NavLink to="/universe-say" className="menu__link menu__link--universe-say"
                       activeClassName="menu__link--universe-say--current" onClick={() => window.scrollTo(0, 0)}>
                <h3 className="menu__project-name">Universe say</h3>
                <p className="menu__project-descr">Personal space of harmony and tranquility</p>
              </NavLink>
            </li>
            <li className="menu__item menu__item--physic" onClick={() => {
              if (width < 1280) {
                setShowMenu(false);
                document.body.style.overflow = 'visible';
              }
            }}>
              <NavLink to="/physics" className="menu__link menu__link--physic" activeClassName="menu__link--physic--current" onClick={() => window.scrollTo(0,0)}>
                <h3 className="menu__project-name">Physics — formulas</h3>
                <p className="menu__project-descr">Formulas and calculators</p>
              </NavLink>
            </li>
            <li className="menu__item menu__item--esenin" onClick={() => {
              if (width < 1280) {
                setShowMenu(false);
                document.body.style.overflow = 'visible';
              }
            }}>
              <NavLink to="/esenin" className="menu__link menu__link--esenin" activeClassName="menu__link--esenin--current" onClick={() => window.scrollTo(0,0)}>
                <h3 className="menu__project-name">Сергей Есенин</h3>
                <p className="menu__project-descr">Sergey Esenin</p>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="menu__bottom-navigation">
          <ul className="menu__socials">
            <SocialLink type={'telegram'} link={'https://t.me/iam_nikitka'}/>
            <SocialLink type={'instagram'} link={'https://www.instagram.com/iam_nikitka/'}/>
          </ul>
        </div>
      </section> : null}
    </section>
  );
};

export default Menu;