import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import 'viewerjs/dist/viewer.min.css';
import Viewer from 'viewerjs';
import {useEffect} from 'react';
import {Helmet} from 'react-helmet';

const EseninPage = () => {

  useEffect(() => {
    const gallery = document.querySelector('.splide__list');
    new Viewer(gallery, {
      movable: false,
      rotatable: false,
      tooltip: false,
      toolbar: false,
      navbar: false,
      title: false,
      zoomOnTouch: false,
      zoomOnWheel: false,
      toggleOnDblclick: false
    });
  }, []);

  const year = new Date().getFullYear();

  return (
    <>
      <Helmet>
        <title>{`Есенин ${year} — chernykh.tech`}</title>
      </Helmet>
      <section className="app-page">
        <div className="app-page__container">
          <div className="app-page__header-wrapper">
            <div className="app-page__icon-wrapper">
              <picture>
                <source type="image/webp" srcSet={`${require('../assets/images/esenin-icon@1x.webp')} 1x,
              ${require('../assets/images/esenin-icon@2x.webp')} 2x`}/>
                <img src={require('../assets/images/esenin-icon@1x.jpg')}
                     srcSet={`${require('../assets/images/esenin-icon@2x.jpg')} 2x`}
                     alt=""
                     className="app-page__icon" />
              </picture>
            </div>
            <div className="app-page__title-wrapper">
              <h2 className="app-page__title">Есенин {year} - <span className="light-green">Сборник</span></h2>
              <div className="app-page__stats">
                <span className="app-page__stats-count app-page__stats-count--downloads">100 000+</span>
                <span className="app-page__stats-count app-page__stats-count--feedback">5 000+</span>
              </div>
            </div>
          </div>
          <div className="app-page__download-links">
            <a href="https://play.google.com/store/apps/details?id=com.august.esenin" className="app-page__download-link app-page__download-link--android" aria-label="Google Play Link" target="_blank" rel="noreferrer">
              <img src={require('../assets/images/google-play-light-badge.svg').default} alt="" />
            </a>
            <a href="http://itunes.apple.com/app/id1448243729" className="app-page__download-link app-page__download-link--ios" aria-label="App Store Link" target="_blank" rel="noreferrer">
              <img src={require('../assets/images/appstore-light-badge.svg').default} alt="" />
            </a>
          </div>
          <p className="app-page__lead">
            Сборник стихотворений великого писателя - Есенина, в твоем телефоне.
          </p>
          <Splide className="app-page__slider" options={{
            type: 'loop',
            pagination: false,
            arrows: true,
            perPage: 4,
            perMove: 1,
            autoplay: true,
            interval: 3000,
            gap: 16,
            breakpoints: {
              1279: {
                perPage: 2,
                arrows: false
              }
            }
          }}>
            <SplideSlide>
              <picture>
                <source type="image/webp" srcSet={require('../assets/images/screens/esenin/1.webp')} />
                <img src={require('../assets/images/screens/esenin/1.jpg')} alt="" />
              </picture>
            </SplideSlide>
            <SplideSlide>
              <picture>
                <source type="image/webp" srcSet={require('../assets/images/screens/esenin/2.webp')} />
                <img src={require('../assets/images/screens/esenin/2.jpg')} alt="" />
              </picture>
            </SplideSlide>
            <SplideSlide>
              <picture>
                <source type="image/webp" srcSet={require('../assets/images/screens/esenin/3.webp')} />
                <img src={require('../assets/images/screens/esenin/3.jpg')} alt="" />
              </picture>
            </SplideSlide>
            <SplideSlide>
              <picture>
                <source type="image/webp" srcSet={require('../assets/images/screens/esenin/4.webp')} />
                <img src={require('../assets/images/screens/esenin/4.jpg')} alt="" />
              </picture>
            </SplideSlide>
            <SplideSlide>
              <picture>
                <source type="image/webp" srcSet={require('../assets/images/screens/esenin/5.webp')} />
                <img src={require('../assets/images/screens/esenin/5.jpg')} alt="" />
              </picture>
            </SplideSlide>
            <SplideSlide>
              <picture>
                <source type="image/webp" srcSet={require('../assets/images/screens/esenin/6.webp')} />
                <img src={require('../assets/images/screens/esenin/6.jpg')} alt="" />
              </picture>
            </SplideSlide>
          </Splide>
          <div className="app-page__description-wrapper">
          </div>
          <p className="app-page__description">
            Сборник содержит более 220 стихотворений Есенина Сергея Александровича. <br/>
            Приложение состоит из двух частей: Стихи и Избранное. <br/>
            Добавляйте любимые стихотворения в Избранное и Вам больше не придется искать их в общем списке. <br/>
            Вы с легкостью можете читать как днем так и ночью, мы сделали переключение дня и ночи, теперь вы с легкостью
            сможете читать в любое время. <br/>
            В одно касание вы можете настроить размер шрифта, и с легкостью сможете читать стихотворение даже
            на вытянутой руке. <br/>
            Уверен что несложный и приятный интерфейс позволит вам насладиться творчеством Есенина.
          </p>
        </div>
      </section>
    </>
  );
};

export default EseninPage;