const PhysicsPrivacyPolicyEnPage = () => {
  return (
    <section className='privacy-policy'>
      <div className='privacy-policy__container'>

        <h2 className='privacy-policy__title'>Terms of Use and Privacy Policy:</h2>
        <p className='privacy-policy__text'>
          This Terms of Use and Privacy Policy (hereinafter referred to as the "Agreement") establishes the terms of use
          of the Mobile Application "Physics: Formulas" and is concluded between the citizen of the Republic of
          Kazakhstan, Nikita Andreevich Chernykh, an individual, hereinafter referred to in the text of the Agreement as
          the "Licensor," who holds the exclusive rights to the Mobile Application "Physics: Formulas," on the one hand,
          and any person using the Mobile Application "Physics: Formulas," on the other hand, hereinafter referred to as
          the "User," and when mentioned together, the "Parties," or separately, a "Party."
          <br/><br/>
          This Agreement, in matters and terms not specifically stipulated within it, is governed by the current
          legislation of the Republic of Kazakhstan. Before starting to use the Mobile Application, the User is
          obligated to familiarize themselves with the Agreement. The commencement of the User’s use of the Mobile
          Application in any form signifies that the User accepts all the terms of the Agreement in full. If the User
          does not agree with the terms of the Agreement or for any reason does not have the right to enter into it,
          they should immediately cease any use of the Mobile Application.
        </p>
        <h3 className='privacy-policy__subtitle'>
          1. Subject of the Agreement:
        </h3>
        <p className='privacy-policy__text'>
          1.1. The Licensor grants the User a free, simple (non-exclusive) license to use the Mobile Application
          worldwide, under the following conditions: <br/>
          — Installation, launching, and using the Mobile Application for its intended functionality. The User has the
          right to install the Mobile Application on an unlimited number of their Devices; <br/>
          — Reproducing and distributing the Mobile Application for non-commercial purposes (free of charge).
          <br/><br/>
          1.2. The Licensor offers the User the option to purchase a Subscription for a fee. The Subscription options
          include extended functionality displayed on the User’s Device.
          <br/><br/>
          1.3. The Agreement does not grant the User exclusive rights to the Mobile Application, only the right to use
          it under the terms of the Agreement. The Licensor remains the owner of the Mobile Application.
          <br/><br/>
          1.4. The Agreement is concluded either before or directly at the time of starting to use the Mobile
          Application and remains in effect for the entire period of the Licensor’s exclusive rights to the Mobile
          Application, provided the User properly complies with the terms of the Agreement. If the User does not agree
          to follow this Agreement, they should not use the Mobile Application.
          <br/><br/>
          1.5. The Licensor has the right to revoke permission to use the Mobile Application at any time.
        </p>
        <h3 className='privacy-policy__subtitle'>
          2. Prohibited Actions:
        </h3>
        <p className='privacy-policy__text'>
          2.1. When using the Mobile Application, the User is not allowed to: <br/>
          — Modify, decompile, disassemble, decrypt, deconstruct, make changes, or perform other actions with the object
          code of the Mobile Application with the aim of obtaining information about the algorithms used in the Mobile
          Application and/or extracting Content, create derivative works using the Mobile Application, or engage in (or
          allow others to engage in) other uses of the Mobile Application without prior written permission from the
          Licensor; <br/>
          — Modify or delete any copyright information; <br/>
          — Change the name of the Mobile Application; <br/>
          — Use the Mobile Application in any way that contradicts the current legislation of the Republic of
          Kazakhstan; <br/>
          — Reproduce, distribute, or provide network access to the Mobile Application for commercial purposes without
          prior written permission from the Licensor. <br/>
        </p>
        <h3 className='privacy-policy__subtitle'>
          3. Property Rights to the Content:
        </h3>
        <p className='privacy-policy__text'>
          3.1. Except as provided by the Agreement and the applicable legislation of the Republic of Kazakhstan, no
          Content may be copied (reproduced), processed, distributed, displayed in another mobile application and/or
          website, published, downloaded, transmitted, sold, or otherwise used in whole or in part without prior
          permission from the copyright holder.
          <br/><br/>
          3.2. The use of Content and materials accessed by the User is allowed solely for personal, non-commercial use,
          provided that all copyright signs (notices) or other authorship notifications are preserved, the author’s name
          remains unchanged, and the work (content unit) remains unchanged.
          <br/><br/>
          3.3. Any use of the Mobile Application or Content, other than explicitly allowed in the Agreement, without
          prior written permission from the copyright holder is strictly prohibited.
        </p>
        <h3 className='privacy-policy__subtitle'>
          4. Updates and Additions:
        </h3>
        <p className='privacy-policy__text'>
          4.1. The terms of the Agreement apply to any updates/new versions, and additions to the Mobile Application,
          unless a separate license agreement accompanies such an update. By agreeing to install the update/new version
          of the Mobile Application, the User accepts the terms of the Agreement for the respective updates/new versions
          unless the update/new version installation is accompanied by a different license agreement.
        </p>
        <h3 className='privacy-policy__subtitle'>
          5. Subscription Payment Terms:
        </h3>
        <p className='privacy-policy__text'>
          5.1. The Subscription Fee is charged from the User no earlier than 24 hours before the start of the first or
          next Subscription Period. If the User decides to cancel the Subscription before the Subscription Fee is
          charged, they must cancel the Subscription before the end of the free trial period.
          <br/><br/>
          — Subscription use is only possible for the User Account from which the Subscription Fee was paid.
          <br/><br/>
          5.2. The Subscription Fee is prepaid by the User under the following conditions:
          <br/><br/>
          5.2.1. The Subscription Fee in the App Store is processed via Apple in-App Purchase subscriptions
          (automatically renewable subscriptions by Apple), descriptions, and rules of which can be found at the
          following link: <a className='privacy-policy__link' href="http://www.apple.com/legal/itunes/ww/"
                             target={"_blank"}
                             rel={'noreferrer'}>http://www.apple.com/legal/itunes/ww/</a>
          <br/><br/>
          5.3. The ability to use the Subscription is provided by the Licensor to the User from the moment the Licensor
          receives confirmation of the funds being charged from the User's Account in iTunes (in the case of payment in
          the App Store). The Licensor is considered to have fulfilled its obligations to provide access to the
          Subscription at the moment the access is granted.
        </p>
        <h3 className='privacy-policy__subtitle'>
          6. Log Data:
        </h3>
        <p className='privacy-policy__text'>
          6.1. The Licensor informs you that when using the Service, in case of an error in the application or other
          software modules, it collects data and information (via third-party products such as Firebase) on your phone.
          This log data may include information such as your device’s Internet protocol address ("IP"), device name,
          operating system version, application configuration when using the Service, the time and date of your use of
          the Service, and other statistics.
        </p>
        <h3 className='privacy-policy__subtitle'>
          7. Contacts:
        </h3>
        <p className='privacy-policy__text'>
          7.1. If you have any questions or need additional information unrelated to data privacy, please contact us at
          the following email address: <br/>
          - Email: <a className='privacy-policy__link' href='mailto:nks199731@gmail.com'>nks199731@gmail.com</a>
          <br/><br/>
          7.2. For questions related to the privacy policy, please contact: <br/>
          - Email: <a className='privacy-policy__link' href='mailto:nks199731@gmail.com'>nks199731@gmail.com</a>
          <br/><br/>
          7.3. If you have issues with your account or need help managing it, please contact: <br/>
          - Email: <a className='privacy-policy__link' href='mailto:nks199731@gmail.com'>nks199731@gmail.com</a>
          <br/><br/>
          7.4. For data deletion requests or feedback, please contact us at: <br/>
          - Email: <a className='privacy-policy__link' href='mailto:nks199731@gmail.com'>nks199731@gmail.com</a>
        </p>
      </div>
    </section>
  );
};

export default PhysicsPrivacyPolicyEnPage;