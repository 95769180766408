const SocialLink = ({type, link}) => {

  const setIcon = () => {
    if (type === 'telegram') {
      return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path className="stroke"
                d="M14.9114 3.07653C14.4982 3.23928 5.77795 6.91203 2.77495 8.17578C2.59945 8.24928 2.2522 8.36178 2.2522 8.72778C2.2522 9.02178 2.47795 9.16728 2.7382 9.27078C3.2467 9.47328 4.81045 10.0988 5.4907 10.371C5.69545 10.4528 5.85295 10.62 5.9227 10.8293C6.1612 11.544 6.71545 13.209 6.85495 13.65C7.0342 14.217 7.1782 14.2305 7.1782 14.2305C7.21795 14.2478 7.25695 14.2545 7.29295 14.2545C7.40845 14.2545 7.49695 14.187 7.49695 14.187L9.2437 12.7095C9.5332 12.465 9.9592 12.474 10.2374 12.7313L12.3937 14.7255C12.3937 14.7255 12.6562 14.9993 12.9464 14.9993C13.2997 14.9993 13.5554 14.6468 13.6004 14.418C13.6619 14.0985 15.7124 3.80253 15.7124 3.80253C15.8984 2.97228 15.3472 2.90478 14.9114 3.07653Z"
                stroke="#27A3E1" strokeWidth="1.6" strokeMiterlimit="10"/>
          <path className="fill"
                d="M12.6001 6C12.5063 6 12.3796 6.08925 12.3121 6.13125C11.3461 6.7335 6.50257 9.48975 5.17432 10.2443C5.54857 10.3943 5.81257 10.5 5.81257 10.5C5.81257 10.5 6.67582 13.0838 6.85507 13.65C7.03432 14.217 7.17832 14.2305 7.17832 14.2305C7.21807 14.2478 7.25707 14.2545 7.29307 14.2545C7.32232 14.2545 7.34257 14.244 7.36732 14.2372L8.25007 10.7812C8.25007 10.7812 12.2723 6.71025 12.5146 6.47325C12.7096 6.2835 12.7501 6.21675 12.7501 6.15075C12.7501 6.063 12.7043 6 12.6001 6Z"
                fill="#27A3E1"/>
        </svg>
      )
    } else if (type === 'instagram') {
      return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.85 1.5H12.15C14.55 1.5 16.5 3.45 16.5 5.85V12.15C16.5 13.3037 16.0417 14.4101 15.2259 15.2259C14.4101 16.0417 13.3037 16.5 12.15 16.5H5.85C3.45 16.5 1.5 14.55 1.5 12.15V5.85C1.5 4.69631 1.9583 3.58987 2.77409 2.77409C3.58987 1.9583 4.69631 1.5 5.85 1.5ZM5.7 3C4.98392 3 4.29716 3.28446 3.79081 3.79081C3.28446 4.29716 3 4.98392 3 5.7V12.3C3 13.7925 4.2075 15 5.7 15H12.3C13.0161 15 13.7028 14.7155 14.2092 14.2092C14.7155 13.7028 15 13.0161 15 12.3V5.7C15 4.2075 13.7925 3 12.3 3H5.7ZM12.9375 4.125C13.1861 4.125 13.4246 4.22377 13.6004 4.39959C13.7762 4.5754 13.875 4.81386 13.875 5.0625C13.875 5.31114 13.7762 5.5496 13.6004 5.72541C13.4246 5.90123 13.1861 6 12.9375 6C12.6889 6 12.4504 5.90123 12.2746 5.72541C12.0988 5.5496 12 5.31114 12 5.0625C12 4.81386 12.0988 4.5754 12.2746 4.39959C12.4504 4.22377 12.6889 4.125 12.9375 4.125ZM9 5.25C9.99456 5.25 10.9484 5.64509 11.6517 6.34835C12.3549 7.05161 12.75 8.00544 12.75 9C12.75 9.99456 12.3549 10.9484 11.6517 11.6517C10.9484 12.3549 9.99456 12.75 9 12.75C8.00544 12.75 7.05161 12.3549 6.34835 11.6517C5.64509 10.9484 5.25 9.99456 5.25 9C5.25 8.00544 5.64509 7.05161 6.34835 6.34835C7.05161 5.64509 8.00544 5.25 9 5.25ZM9 6.75C8.40326 6.75 7.83097 6.98705 7.40901 7.40901C6.98705 7.83097 6.75 8.40326 6.75 9C6.75 9.59674 6.98705 10.169 7.40901 10.591C7.83097 11.0129 8.40326 11.25 9 11.25C9.59674 11.25 10.169 11.0129 10.591 10.591C11.0129 10.169 11.25 9.59674 11.25 9C11.25 8.40326 11.0129 7.83097 10.591 7.40901C10.169 6.98705 9.59674 6.75 9 6.75Z"
            fill="#EF476F"/>
        </svg>
      )
    }
  }

  return (
    <li className="menu__social-item menu__social-item--telegram">
      <a href={link} className={`menu__social-link menu__social-link--${type}`} aria-label={type}
         target="_blank" rel="noreferrer">
        {setIcon()}
      </a>
    </li>
  )
}

export default SocialLink;