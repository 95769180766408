const PhysicsPrivacyPolicyRuPage = () => {
  return (
    <section className='privacy-policy'>
      <div className='privacy-policy__container'>

        <h2 className='privacy-policy__title'>Условия использования приложения и Политика конфиденциальности:</h2>
        <p className='privacy-policy__text'>
          Настоящее Условия Использования и Политика конфиденциальности (далее — Соглашение) устанавливает условия
          использования Мобильного приложения «Физика: Формулы» и заключено между гражданином Республики Казахстан
          Черныx Никитой Андреевичем, физическим лицом, далее по тексту Соглашения именуемым «Лицензиар», являющимся
          обладателем исключительного права на Мобильное приложение «Физика: Формулы», с одной стороны, и любым лицом,
          использующим Мобильное приложение «Физика: Формулы», с другой стороны, далее по тексту Соглашения именуемым
          «Пользователь», а при совместном упоминании — «Стороны», а по отдельности — «Сторона».
          <br/><br/>
          Настоящее Соглашение в вопросах и условиях, не оговоренных в нем, регулируется действующим законодательством
          Республики Казахстан. Перед началом использования Мобильного приложения Пользователь обязан ознакомиться с
          Соглашением. Начало использования Пользователем Мобильного приложения в любой форме означает, что Пользователь
          принимает все условия Соглашения в полном объеме. Если Пользователь не согласен с условиями Соглашения или по
          каким-либо причинам не имеет права на его заключение, ему следует немедленно прекратить любое использование
          Мобильного приложения.
        </p>
        <h3 className='privacy-policy__subtitle'>
          1. Предмет Соглашения:
        </h3>
        <p className='privacy-policy__text'>
          1.1. Лицензиар бесплатно, на условиях простой (неисключительной) лицензии, предоставляет Пользователю право
          использования Мобильного приложения на территории всех стран мира, следующими способами: <br/>
          — установки, запуска и использования Мобильного приложения по его прямому функциональному назначению.
          Пользователь вправе произвести установку Мобильного приложения на неограниченное число Устройств
          Пользователя; <br/>
          — воспроизведения и распространения Мобильного приложения в некоммерческих целях (бесплатно).
          <br/><br/>
          1.2. Лицензиар за плату предоставляет Пользователю возможность приобрести Подписку. Опции Подписки включают в
          себя расширенный функционал, отображаемого на Устройстве Пользователя.
          <br/><br/>
          1.3. Соглашение не предоставляет Пользователю исключительное право на Мобильное приложение, а только право
          использования в соответствии с условиями Соглашения. Лицензиар остается собственником Мобильного приложения.
          <br/><br/>
          1.4. Соглашение заключается до или непосредственно в момент начала использования Мобильного приложения и
          действует на протяжении всего срока действия исключительного права Лицензиара на Мобильное приложение при
          условии надлежащего выполнения Пользователем условий Соглашения. Если Пользователь не согласен соблюдать
          настоящее Соглашение, такой Пользователь не должен использовать Мобильное Приложение.
          <br/><br/>
          1.5. Лицензиар вправе в любое время отозвать свое разрешение на использование Мобильного приложения.
        </p>
        <h3 className='privacy-policy__subtitle'>
          2. Запрещенные действия:
        </h3>
        <p className='privacy-policy__text'>
          2.1. При использовании Мобильного приложения Пользователь не имеет права: <br/>
          — Изменять, декомпилировать, дизассемблировать, дешифровать, разбирать на части, вносить изменения и
          производить иные действия с объектным кодом Мобильного приложения, имеющие целью получение информации о
          реализации алгоритмов, используемых в Мобильном приложении и/или извлечение Контента, создавать производные
          произведения с использованием Мобильного приложения, а также осуществлять (разрешать осуществлять) иное
          использование Мобильного приложения без предварительного письменного разрешения Лицензиара; <br/>
          — Изменять или удалять любую информацию об авторских правах; <br/>
          — Изменять наименование Мобильного приложения; <br/>
          — Использовать Мобильное приложение любым способом, противоречащим действующему законодательству Республики
          Казахстан; <br/>
          — Воспроизводить, распространять или предоставлять сетевой доступ к Мобильному приложению в коммерческих целях
          без предварительного письменного разрешения Лицензиара. <br/>
        </p>
        <h3 className='privacy-policy__subtitle'>
          3. Имущественные права на Контент:
        </h3>
        <p className='privacy-policy__text'>
          3.1. Кроме случаев, установленных Соглашением, а также действующим законодательством Республики Казахстан,
          никакой Контент не может быть скопирован (воспроизведен), переработан, распространен, отображен в другом
          мобильном приложении и/или сайте, опубликован, скачан, передан, продан или иным способом использован целиком
          или по частям без предварительного разрешения правообладателя.
          <br/><br/>
          3.2. Использование Контента и материалов, к которым Пользователь получил доступ, исключительно для личного
          некоммерческого использования, допускается при условии сохранения всех знаков авторства (копирайтов) или
          других уведомлений об авторстве, сохранения имени автора в неизменном виде и сохранения произведения (единицы
          Контента) в неизменном виде.
          <br/><br/>
          3.3. Любое использование Мобильного приложения или Контента, кроме прямо разрешенного в Соглашении, без
          предварительного письменного разрешения правообладателя категорически запрещено.
        </p>
        <h3 className='privacy-policy__subtitle'>
          4. Обновления и дополнения:
        </h3>
        <p className='privacy-policy__text'>
          4.1. Условия Соглашения распространяются на любые обновления/новые версии, дополнения Мобильного Приложения,
          за исключением случаев, когда к такому обновлению прилагается отдельное лицензионное соглашение. Соглашаясь с
          установкой обновления/новой версии, дополнения Мобильного приложения, Пользователь принимает условия
          Соглашения для соответствующих обновлений/новых версий Мобильного приложения, если обновление/установка новой
          версии Мобильного приложения не сопровождается иным лицензионным соглашением.
        </p>
        <h3 className='privacy-policy__subtitle'>
          5. Условия оплаты Подписки:
        </h3>
        <p className='privacy-policy__text'>
          5.1. Стоимость Подписки списывается с Пользователя не ранее чем за 24 часа до начала первого или очередного
          Периода Подписки. В случае если Пользователь решил отказаться от Подписки до того, как с него будет списана
          Стоимость Подписки, ему необходимо отменить Подписку до окончания бесплатного пробного периода использования
          Подписки. <br/>
          — Использование Подписки возможно только для той Учетной записи Пользователя, с которой были списаны денежные
          средства на оплату Стоимости Подписки.
          <br/><br/>
          5.2. Оплата Стоимости Подписки производится Пользователем в порядке предварительной оплаты на следующих
          условиях:
          <br/><br/>
          5.2.1. Оплата Стоимости Подписки в магазине приложений App Store осуществляется через подписки Apple in-App
          Purchase (автоматически возобновляемые подписки Apple), ознакомиться с описаниями и правилами работы которых
          можно по ссылке: <a className='privacy-policy__link' href="http://www.apple.com/legal/itunes/ww/"
                              target={"_blank"}
                              rel={'noreferrer'}>http://www.apple.com/legal/itunes/ww/</a>.
          <br/><br/>
          5.3. Возможность использования Подписки предоставляется Лицензиаром Пользователю с момента получения
          Лицензиаром подтверждения о списании средств c Учётной записи Пользователя в iTunes (в случае оплаты в
          магазине приложений App Store). Лицензиар считается исполнившим свои обязательства по предоставлению
          возможности использования Подписки в момент предоставления возможности.
        </p>
        <h3 className='privacy-policy__subtitle'>
          6. Данные журнала:
        </h3>
        <p className='privacy-policy__text'>
          6.1. Лицензиар информирует вас, что при использовании моего Сервиса, в случае возникновения ошибки в
          приложении или других программных модулях, он собирает данные и информацию (через сторонние продукты, такие
          как Firebase) на вашем телефоне. Эти данные журнала могут включать в себя такую информацию, как адрес
          интернет-протокола вашего устройства («IP»), имя устройства, версия операционной системы, конфигурация
          приложения при использовании моего Сервиса, время и дата использования вами Сервиса и другую статистику.
        </p>
        <h3 className='privacy-policy__subtitle'>
          7. Контакты:
        </h3>
        <p className='privacy-policy__text'>
          7.1. Если у вас возникли вопросы или требуется дополнительная информация, не связанная с конфиденциальностью
          данных, свяжитесь с нами по следующим контактам: <br/>
          - Электронная почта: <a className='privacy-policy__link' href='mailto:nks199731@gmail.com'>nks199731@gmail.com</a>
          <br/><br/>
          7.2. Для вопросов, связанных с политикой конфиденциальности, обращайтесь по адресу: <br/>
          - Электронная почта: <a className='privacy-policy__link' href='mailto:nks199731@gmail.com'>nks199731@gmail.com</a>
          <br/><br/>
          7.3. Если у вас возникли проблемы с учетной записью или вам требуется помощь в ее управлении, обращайтесь по
          следующим контактам: <br/>
          - Электронная почта: <a className='privacy-policy__link' href='mailto:nks199731@gmail.com'>nks199731@gmail.com</a>
          <br/><br/>
          7.4. Для запросов относительно удаления данных или предоставления отзыва, свяжитесь с нами по адресу: <br/>
          - Электронная почта: <a className='privacy-policy__link' href='mailto:nks199731@gmail.com'>nks199731@gmail.com</a>
        </p>
      </div>
    </section>
  );
};

export default PhysicsPrivacyPolicyRuPage;