import Menu from './components/Menu.js';
import Promo from './pages/Promo.js';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import PtMobilePage from './pages/PtMobilePage.js';
import PtWebPage from './pages/PtWebPage.js';
import PtGamePage from './pages/PtGamePage.js';
import PhysicsPage from './pages/PhysicsPage.js';
import EseninPage from './pages/EseninPage.js';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage.js';
import PrivacyPolicyIosPage from './pages/PrivacyPolicyIosPage.js';
import FaqPage from "./pages/FaqPage";
import FaqAnswerPage from "./pages/FaqAnswerPage";
// import ReviewsPage from "./pages/ReviewsPage";
// import FeedbackPage from "./pages/FeedbackPage";
import UniverseSayPage from "./pages/UniverseSayPage";
import PhysicsPrivacyPolicyEnPage from "./pages/PhysicsPrivacyPolicyEnPage";
import PhysicsPrivacyPolicyRuPage from "./pages/PhysicsPrivacyPolicyRuPage";

const App = () => {

  return (
    <Router>
      <div className="page-wrapper">
        <Menu/>
        <main className="page-main">
          <Route render={({location}) => (
            <TransitionGroup>
              <CSSTransition key={location.key} timeout={450} classNames="fade">
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => (
                      <Promo/>
                    )}
                  />
                  <Route
                    exact
                    path="/pt-mobile"
                    render={() => (
                      <PtMobilePage/>
                    )}
                  />
                  <Route
                    exact
                    path="/pt-web"
                    render={() => (
                      <PtWebPage/>
                    )}
                  />
                  <Route
                    exact
                    path="/pt-game"
                    render={() => (
                      <PtGamePage/>
                    )}
                  />
                  <Route
                    exact
                    path="/universe-say"
                    render={() => (
                      <UniverseSayPage/>
                    )}
                  />
                  <Route
                    exact
                    path="/physics"
                    render={() => (
                      <PhysicsPage/>
                    )}
                  />
                  <Route
                    exact
                    path="/esenin"
                    render={() => (
                      <EseninPage/>
                    )}
                  />
                  <Route
                    exact
                    path="/faq"
                    render={() => (
                      <FaqPage/>
                    )}
                  />
                  <Route
                    exact
                    path="/faq/:id"
                    render={() => (
                      <FaqAnswerPage/>
                    )}
                  />
                  {/*<Route*/}
                  {/*  exact*/}
                  {/*  path="/reviews"*/}
                  {/*  render={() => (*/}
                  {/*    <ReviewsPage/>*/}
                  {/*  )}*/}
                  {/*/>*/}
                  {/*<Route*/}
                  {/*  exact*/}
                  {/*  path="/reviews/:id"*/}
                  {/*  render={() => (*/}
                  {/*    <FeedbackPage/>*/}
                  {/*  )}*/}
                  {/*/>*/}
                  <Route exact path='/privacy-policy' render={() => <PrivacyPolicyPage/>}/>
                  <Route exact path='/privacy-policy-ios' render={() => <PrivacyPolicyIosPage/>}/>
                  <Route exact path='/physics/privacy-policy/en' render={() => <PhysicsPrivacyPolicyEnPage/>}/>
                  <Route exact path='/physics/privacy-policy/ru' render={() => <PhysicsPrivacyPolicyRuPage/>}/>
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          )}/>
        </main>
      </div>
    </Router>
  );
};

export default App;
