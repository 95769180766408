import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import 'viewerjs/dist/viewer.min.css';
import Viewer from 'viewerjs';
import {useEffect} from 'react';
import {Helmet} from 'react-helmet';

const PtGamePage = () => {

  useEffect(() => {
    const gallery = document.querySelector('.splide__list');
    new Viewer(gallery, {
      movable: false,
      rotatable: false,
      tooltip: false,
      toolbar: false,
      navbar: false,
      title: false,
      zoomOnTouch: false,
      zoomOnWheel: false,
      toggleOnDblclick: false
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Periodic Table Game — chernykh.tech</title>
      </Helmet>
      <section className="app-page">
        <div className="app-page__container">
          <div className="app-page__header-wrapper">
            <div className="app-page__icon-wrapper">
              <picture>
                <source type="image/webp" srcSet={`${require('../assets/images/game-icon@1x.webp')} 1x,
              ${require('../assets/images/game-icon@2x.webp')} 2x`}/>
                <img src={require('../assets/images/game-icon@1x.jpg')}
                     srcSet={`${require('../assets/images/game-icon@2x.jpg')} 2x`}
                     alt=""
                     className="app-page__icon" />
              </picture>
            </div>
            <div className="app-page__title-wrapper">
              <h2 className="app-page__title">Periodic Table - <span className="blue">Game</span></h2>
              <div className="app-page__stats">
                <span className="app-page__stats-count app-page__stats-count--downloads">100 000+</span>
                <span className="app-page__stats-count app-page__stats-count--feedback">7 400</span>
              </div>
            </div>
          </div>
          <div className="app-page__download-links">
            <a href="https://play.google.com/store/apps/details?id=august.mendeleev.quiz" className="app-page__download-link app-page__download-link--android" aria-label="Google Play Link" target="_blank" rel="noreferrer">
              <img src={require('../assets/images/google-play-light-badge.svg').default} alt="" />
            </a>
          </div>
          <p className="app-page__lead">
            <span className="red">Periodic Table - Game,</span> learn new things about the chemical elements in a playful way. It's simple)
          </p>
          <Splide className="app-page__slider" options={{
            type: 'loop',
            pagination: false,
            arrows: true,
            perPage: 4,
            perMove: 1,
            autoplay: true,
            interval: 3000,
            gap: 16,
            breakpoints: {
              1279: {
                perPage: 2,
                arrows: false
              }
            }
          }}>
            <SplideSlide>
              <picture>
                <source type="image/webp" srcSet={require('../assets/images/screens/pt-game/1.webp')} />
                <img src={require('../assets/images/screens/pt-game/1.jpg')} alt="" />
              </picture>
            </SplideSlide>
            <SplideSlide>
              <picture>
                <source type="image/webp" srcSet={require('../assets/images/screens/pt-game/2.webp')} />
                <img src={require('../assets/images/screens/pt-game/2.jpg')} alt="" />
              </picture>
            </SplideSlide>
            <SplideSlide>
              <picture>
                <source type="image/webp" srcSet={require('../assets/images/screens/pt-game/3.webp')} />
                <img src={require('../assets/images/screens/pt-game/3.jpg')} alt="" />
              </picture>
            </SplideSlide>
            <SplideSlide>
              <picture>
                <source type="image/webp" srcSet={require('../assets/images/screens/pt-game/4.webp')} />
                <img src={require('../assets/images/screens/pt-game/4.jpg')} alt="" />
              </picture>
            </SplideSlide>
            <SplideSlide>
              <picture>
                <source type="image/webp" srcSet={require('../assets/images/screens/pt-game/5.webp')} />
                <img src={require('../assets/images/screens/pt-game/5.jpg')} alt="" />
              </picture>
            </SplideSlide>
            <SplideSlide>
              <picture>
                <source type="image/webp" srcSet={require('../assets/images/screens/pt-game/6.webp')} />
                <img src={require('../assets/images/screens/pt-game/6.jpg')} alt="" />
              </picture>
            </SplideSlide>
            <SplideSlide>
              <picture>
                <source type="image/webp" srcSet={require('../assets/images/screens/pt-game/7.webp')} />
                <img src={require('../assets/images/screens/pt-game/7.jpg')} alt="" />
              </picture>
            </SplideSlide>
          </Splide>
          <div className="app-page__description-wrapper">
          </div>
          <p className="app-page__description">
            «Periodic Table - Game" is designed for schoolchildren, students, chemists with experience and for people
            who want to learn even more about our world. We have collected a huge amount of information about the
            chemical elements, which is easy to remember thanks to the game form of learning. It's simple, you just
            need to play regularly, gain more "Atoms" and unlock new games and levels of difficulty! <br/>
            Our game will also be useful for preparing for tests, quizzes and even important exams! Just play the
            game in between studying the basic material.
          </p>
          <br />
          <p className="app-page__description">
            The game is under active development, with each update it will get better and better, more beautiful,
            informative and interesting, follow the updates of our Game and do not forget to leave feedback. Thanks to
            your feedback we will make the Game even better and know what to strive for!
          </p>
          <br />
        </div>
      </section>
    </>
  );
};

export default PtGamePage;