import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import 'viewerjs/dist/viewer.min.css';
import Viewer from 'viewerjs';
import {useEffect} from 'react';
import {Helmet} from 'react-helmet';

const UniverseSayPage = () => {

  useEffect(() => {
    const gallery = document.querySelector('.splide__list');
    new Viewer(gallery, {
      movable: false,
      rotatable: false,
      tooltip: false,
      toolbar: false,
      navbar: false,
      title: false,
      zoomOnTouch: false,
      zoomOnWheel: false,
      toggleOnDblclick: false
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Universe Say — chernykh.tech</title>
      </Helmet>
      <section className="app-page">
        <div className="app-page__container">
          <div className="app-page__header-wrapper app-page__header-wrapper--empty">
            <div className="app-page__icon-wrapper">
              <picture>
                <source type="image/webp" srcSet={`${require('../assets/images/universe-say-icon@1x.webp')} 1x,
              ${require('../assets/images/universe-say-icon@2x.webp')} 2x`}/>
                <img src={require('../assets/images/universe-say-icon@1x.jpg')}
                     srcSet={`${require('../assets/images/universe-say-icon@2x.jpg')} 2x`}
                     alt=""
                     className="app-page__icon"/>
              </picture>
            </div>
            <div className="app-page__title-wrapper">
              <h2 className="app-page__title">Universe say...</h2>
              {/*<div className="app-page__stats">*/}
              {/*  <span className="app-page__stats-count app-page__stats-count--downloads">7 000 000 +</span>*/}
              {/*  <span className="app-page__stats-count app-page__stats-count--feedback">423 000 +</span>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className="app-page__links">
            <div className="app-page__download-links">
              <a href="https://itunes.apple.com/app/id6470624180"
                 className="app-page__download-link app-page__download-link--ios" aria-label="App Store Link"
                 target="_blank" rel="noreferrer">
                <img src={require('../assets/images/appstore-light-badge.svg').default} alt=""/>
              </a>
            </div>
          </div>
          <p className="app-page__lead">
            The <span className="red">Universe say</span> – your personal space of harmony and tranquility! This unique
            application is designed for
            you and your well-being. In this virtual space, you will immerse yourself in an atmosphere of harmony,
            receiving guidance, motivation, and inspiration from the Universe itself.
          </p>
          <Splide className="app-page__slider" options={{
            type: 'loop',
            pagination: false,
            arrows: true,
            perPage: 4,
            perMove: 1,
            autoplay: true,
            interval: 3000,
            gap: 16,
            breakpoints: {
              1279: {
                perPage: 2,
                arrows: false
              }
            }
          }}>
            <SplideSlide>
              <picture>
                <source type="image/webp" srcSet={require('../assets/images/screens/universe-say/1.webp')}/>
                <img src={require('../assets/images/screens/universe-say/1.jpg')} alt=""/>
              </picture>
            </SplideSlide>
            <SplideSlide>
              <picture>
                <source type="image/webp" srcSet={require('../assets/images/screens/universe-say/2.webp')}/>
                <img src={require('../assets/images/screens/universe-say/2.jpg')} alt=""/>
              </picture>
            </SplideSlide>
            <SplideSlide>
              <picture>
                <source type="image/webp" srcSet={require('../assets/images/screens/universe-say/3.webp')}/>
                <img src={require('../assets/images/screens/universe-say/3.jpg')} alt=""/>
              </picture>
            </SplideSlide>
            <SplideSlide>
              <picture>
                <source type="image/webp" srcSet={require('../assets/images/screens/universe-say/4.webp')}/>
                <img src={require('../assets/images/screens/universe-say/4.jpg')} alt=""/>
              </picture>
            </SplideSlide>
            <SplideSlide>
              <picture>
                <source type="image/webp" srcSet={require('../assets/images/screens/universe-say/5.webp')}/>
                <img src={require('../assets/images/screens/universe-say/5.jpg')} alt=""/>
              </picture>
            </SplideSlide>
            <SplideSlide>
              <picture>
                <source type="image/webp" srcSet={require('../assets/images/screens/universe-say/6.webp')}/>
                <img src={require('../assets/images/screens/universe-say/6.jpg')} alt=""/>
              </picture>
            </SplideSlide>
            <SplideSlide>
              <picture>
                <source type="image/webp" srcSet={require('../assets/images/screens/universe-say/7.webp')}/>
                <img src={require('../assets/images/screens/universe-say/7.jpg')} alt=""/>
              </picture>
            </SplideSlide>
          </Splide>
          <div className="app-page__description-wrapper">
          </div>
          <p className="app-page__description">
            In the hustle and bustle of modern life, we often lose sight of crucial aspects of our well-being.
            The lack of proper sleep and constant tension become invisible foes, leaving us more nervous and irritable.
            However, at times, the solution may be closer than it seems. Incorporating motivational affirmations and
            breathing techniques into our daily routine can be a powerful tool for restoring balance.
            By giving ourselves positive affirmations and focusing on deep breathing, we can not only calm
            the mind but also restore energetic equilibrium. Mindfulness plays a key role in this process,
            helping us find inner harmony and cope with the challenges of modern life.
          </p>
          <br/>
          <p className="app-page__description">
            The Universe say – your personal space of harmony and tranquility! This unique application is designed
            for you and your well-being. In this virtual space, you will immerse yourself in an atmosphere of harmony,
            receiving guidance, motivation, and inspiration from the Universe itself. Allow yourself to receive daily
            doses of positive affirmations and support that will remind you of the importance of
            the moment and the power of your inner peace. Notifications on your phone will be like stars in the sky,
            reminding you of the beauty of every moment. The endless stream of affirmations will fill your days with
            inspiration. Let's create a space together where each breath is filled with tranquility.
          </p>
          <br/>
          <p className="app-page__description">
            The boundless power of breath is gathered in one place – in the "Breathing" section. Our breathing
            exercises will help you focus, relax, or quickly dive into sleep. You can also customize the cycles
            according to your requirements: to invigorate, relax, or concentrate. Breath management
            is simple and beneficial.
          </p>
        </div>
      </section>
    </>
  );
};

export default UniverseSayPage;