import {questions} from "../data/questions";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";

const FaqAnswerPage = () => {
  const {id} = useParams();
  const [answer, setAnswer] = useState();

  useEffect(() => {
    if (id) {
      const answer = questions.find(item => item.id === Number(id));
      setAnswer(answer);
    }
  }, [id])

  return (
    answer &&
    <>
      <Helmet>
        <title>{answer.title} — chernykh.tech</title>
      </Helmet>
      <section className="faq-answer">
        <div className="faq-answer__container app-page__container">
          <h2 className="faq-answer__title app-page__title">{answer.title}</h2>
          <p className="faq-answer__description">Frequently asked questions</p>


          <h3 className="faq-answer__subtitle">Short instructions:</h3>
          <p className="faq-answer__short-instruction">{answer.shortDescription}</p>

          <div className="faq-answer__list-wrapper">
            <h3 className="faq-answer__subtitle">Full instructions:</h3>
            <ul className="faq-answer__image-list">
              {answer.steps.map((step, index) => (
                <li className="faq-answer__item">
                  <div className="faq-answer__item-image-wrapper">
                    <picture>
                      <source type="image/webp"
                              srcSet={require(`../assets/images/faq/screens/${answer.id}/screen-${index + 1}.webp`)}/>
                      <img src={require(`../assets/images/faq/screens/${answer.id}/screen-${index + 1}.jpg`)} alt=""/>
                    </picture>
                  </div>
                  <p className="faq-answer__item-description">{`${index + 1}: ${step}`}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default FaqAnswerPage;