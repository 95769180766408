const PrivacyPolicyIosPage = () => {
  return (
    <section className='privacy-policy'>
      <div className='privacy-policy__container'>
        <h2 className='privacy-policy__title'>Privacy Policy</h2>
        <p className='privacy-policy__text'>
          Nikita Chernykh(Chernykh Technology) built this app as a Free app. This App is provided by Nikita
          Chernykh(Chernykh Technology) at no cost and is intended for use as is. <br />
          This page is used to inform visitors regarding my policies with the collection, use, and disclosure of
          Personal Information if anyone decided to use my Service.
          If you choose to use my Service, then you agree to the collection and use of information in relation to this
          policy. The Personal Information that I collect is used for providing and improving the Service. I will not
          use or share your information with anyone except as described in this Privacy Policy.
          The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is
          accessible at Сергей Есенин unless otherwise defined in this Privacy Policy.
        </p>
        <h3 className='privacy-policy__subtitle'>
          Information collection and use
        </h3>
        <p className='privacy-policy__text'>
          For a better experience, while using our Service, I may require you to provide us with certain personally
          identifiable information. The information that I request will be retained on your device and is not collected
          by me in any way. <br />
          The app does use third party services that may collect information used to identify you. <br />
          Link to privacy policy of third party service providers used by the app
        </p>
        <h3 className='privacy-policy__subtitle'>
          Log data
        </h3>
        <p className='privacy-policy__text'>
          I want to inform you that whenever you use my Service, in a case of an error in the app I collect data and
          information (through third party products) on your phone called Log Data. This Log Data may include
          information such as your device Internet Protocol (“IP”) address, device name, operating system version, the
          configuration of the app when utilizing my Service, the time and date of your use of the Service, and other
          statistics.
        </p>
        <h3 className='privacy-policy__subtitle'>
          Security
        </h3>
        <p className='privacy-policy__text'>
          I value your trust in providing us your Personal Information, thus we are striving to use commercially
          acceptable means of protecting it. But remember that no method of transmission over the internet, or method of
          electronic storage is 100% secure and reliable, and I cannot guarantee its absolute security.
        </p>
        <h3 className='privacy-policy__subtitle'>
          Links to the other sites
        </h3>
        <p className='privacy-policy__text'>
          This Service may contain links to other sites. If you click on a third-party link, you will be directed to
          that site. Note that these external sites are not operated by me. Therefore, I strongly advise you to review
          the Privacy Policy of these websites. I have no control over and assume no responsibility for the content,
          privacy policies, or practices of any third-party sites or services.
        </p>
        <h3 className='privacy-policy__subtitle'>
          Changes to this privacy policy
        </h3>
        <p className='privacy-policy__text'>
          I may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for
          any changes. I will notify you of any changes by posting the new Privacy Policy on this page. These changes
          are effective immediately after they are posted on this page.
        </p>
        <h3 className='privacy-policy__subtitle'>
          Contact us
        </h3>
        <p className='privacy-policy__text'>
          If you have any questions about our privacy policy, please email
          us: <a className='privacy-policy__link' href='mailto:nks199731@gmail.com' target="_blank" rel="noreferrer">nks199731@gmail.com</a>
        </p>
      </div>
    </section>
  );
};

export default PrivacyPolicyIosPage;