export const questions = [
  {
    id: 1,
    title: 'What are the advantages of the PRO version?',
    shortDescription: 'Additional properties of Chemical Elements in PRO version:\n' +
      '+ Molar heat of vaporization\n' +
      '+ Molar heat of fusion\n' +
      '+ Specific heat capacity\n' +
      '+ Thermal expansion\n' +
      '+ Liquid density\n' +
      '+ Volumetric modulus of elasticity\n' +
      '+ Young\'s modulus of elasticity\n' +
      '+ Heat transfer coefficient\n' +
      '+ Speed of sound\n' +
      '+ Refractive index\n' +
      '+ Poisson\'s ratio\n' +
      '+ Shear modulus\n' +
      '+ Brinell hardness\n' +
      '+ Mohs hardness\n' +
      '+ Vickers hardness\n' +
      '+ Half-life\n' +
      '+ Radioactivity\n' +
      '+ Period of existence of an element\n' +
      '+ Name of spatial symmetry group\n' +
      '+ Number of spatial symmetry group\n' +
      '+ CID number\n' +
      '+ RTEC number\n' +
      '+ Nuclear effective cross section\n' +
      '+ Standard electrode potentials\n' +
      '+ Molar volume\n' +
      '+ Country of discovery',
    previewIcon: 'ic_crown.svg',
    steps: ['Properties of elements', 'Comparison of elements', 'Calculator', 'Chemical reactions', 'Emission spectrum', 'Isotopes']
  },
  {
    id: 2,
    title: 'How to enable fullscreen mode?',
    shortDescription: 'Other  >  Setting  >  General setting > Full screen table',
    previewIcon: 'ic_full.svg',
    steps: ['Open Settings', 'Select - Full screen']
  },
  {
    id: 3,
    title: 'How to switch to another language in the application?',
    shortDescription: 'Other  >  Setting  >  General setting > Language',
    previewIcon: 'ic_lang.svg',
    steps: ['Open Settings', 'Language', 'Select']
  },
  {
    id: 4,
    title: 'How to recognize radioactive chemical elements?',
    shortDescription: 'Read more',
    previewIcon: 'ic_radio.svg',
    steps: ['Select element', 'Read element', 'Select']
  },
  {
    id: 5,
    title: 'How to sort Metalloids from other chemical elements?',
    shortDescription: 'Table > Category',
    previewIcon: 'ic_sort.svg',
    steps: ['Periodic Table', 'Open the filter list', 'Select family/category', 'Profit']
  },
  {
    id: 6,
    title: 'How to find a chemical element by symbols?',
    shortDescription: 'Search > Filter > Element symbol',
    previewIcon: 'ic_sort.svg',
    steps: ['Search', 'Filters', 'Element symbol']
  }
]